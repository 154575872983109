'use client';

import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useIntersectionRatio from '@/components/hooks/useIntersectionRatio';
import usePreAndPostIntersect from '@/components/hooks/usePreAndPostIntersect';
import useScrollBox from '@/components/hooks/useScrollBox';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

const Scrollable: React.FunctionComponent<{
  children: React.ReactNode;
  hideScrollbar?: boolean;
  hideShadows?: boolean;
  hideArrows?: boolean;
  className?: string;
  vertical?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const [shadowLeftVisible, setShadowLeftVisible] = React.useState(false);
  const [shadowRightVisible, setShadowRightVisible] = React.useState(false);
  const [interactive, setInteractive] = React.useState(false);
  const scrollWrapperRef = React.useRef<HTMLDivElement | null>(null);

  const { pre, post } = usePreAndPostIntersect();

  const { isDragging } = useScrollBox(scrollWrapperRef);
  useIntersectionRatio(pre, setShadowLeftVisible);
  useIntersectionRatio(post, setShadowRightVisible);

  React.useEffect(() => {
    if (scrollWrapperRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const c = entry.target;
          if (c.scrollWidth > c.clientWidth || c.scrollHeight > c.clientHeight) {
            setInteractive(true);
          } else {
            setInteractive(false);
          }
        }
      });

      observer.observe(scrollWrapperRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div
      className={classNames(
        styles['scrollable'],
        {
          [styles['noshadows']]: props.hideShadows,
          [styles['horizontal']]: !props.vertical,
          [styles['vertical']]: props.vertical,
        },
        props.className,
      )}
    >
      {!props.hideArrows && (
        <div
          className={classNames(styles['scrollable__arrow'], styles['scrollable__arrow__left'])}
          onClick={(e) => {
            e.preventDefault();
            const target = scrollWrapperRef.current;
            if (target) {
              target.scrollBy({
                top: 0,
                left: target.getBoundingClientRect().width * -1,
                behavior: 'smooth',
              });
            }
          }}
          role="button"
          title={t('generic.actions.scrollLeft')}
        >
          <Icon
            width={24}
            height={24}
            kind="chevron-left"
          />
        </div>
      )}
      <div
        className={classNames(styles['scrollable__scroller'], {
          [styles['dragging']]: isDragging,
          [styles['noscroll']]: props.hideScrollbar,
          [styles['interactive']]: interactive,
        })}
        ref={scrollWrapperRef}
      >
        <div className={classNames(styles['scrollable__shadow-left'], { [styles['visible']]: shadowLeftVisible })} />
        <div
          className={styles['scrollable__sentinel-left']}
          ref={pre.node}
        />
        {props.children}
        <div
          className={styles['scrollable__sentinel-right']}
          ref={post.node}
        />
        <div className={classNames(styles['scrollable__shadow-right'], { [styles['visible']]: shadowRightVisible })} />
      </div>
      {!props.hideArrows && (
        <div
          className={classNames(styles['scrollable__arrow'], styles['scrollable__arrow__right'])}
          onClick={(e) => {
            e.preventDefault();
            const target = scrollWrapperRef.current;
            if (target) {
              target.scrollBy({
                top: 0,
                left: target.getBoundingClientRect().width,
                behavior: 'smooth',
              });
            }
          }}
          role="button"
          title={t('generic.actions.scrollRight')}
        >
          <Icon
            width={24}
            height={24}
            kind="chevron-right"
          />
        </div>
      )}
    </div>
  );
};

Scrollable.displayName = 'Scrollable';

export default Scrollable;
